<template>
  <div class="py-16">
    <div class="mb-10 lg:max-w-5xl md:mb-12">
      <h2
        class="mb-6 text-3xl font-bold leading-none tracking-tight  md:text-5xl text-purple"
      >
        RPC Pool
      </h2>
      <h2
        class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900  md:text-3xl"
      >
        The original Solana RPC Pool service
      </h2>
      <p class="text-base text-gray-700 md:text-lg">
        Connect your app or service to the Solana Blockchain using our RPC Pool
        service. We run the largest fleet of Solana RPC servers in the world and
        we know how to provide you fast, reliable and performant access.
      </p>
    </div>
    <div class="grid gap-24 lg:grid-cols-2 sm:mx-auto">
      <div class="flex flex-col justify-center">
        <div class="flex">
          <div class="mr-4">
            <div
              class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50"
            >
              <svg
                class="w-8 h-8 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                ></polygon>
              </svg>
            </div>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5">
              Carpooling or need your own lane?
            </h6>
            <p class="text-sm text-gray-500 md:text-base">
              From small developers to the largest dApps, we offer shared or
              dedicated pools tailored to your needs. We have no limits on our capacity to  grow
              with you as your service or app grows.
            </p>
            <hr class="w-full my-6 border-gray-300" />
          </div>
        </div>
        <div class="flex">
          <div class="mr-4">
            <div
              class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50"
            >
              <svg
                class="w-8 h-8 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                ></polygon>
              </svg>
            </div>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5">
              Planning something big?
            </h6>
            <p class="text-sm text-gray-500 md:text-base">
              We'll provide a dedicated technical team to help you plan and test
              your setup for your events. We have successfully supported the
              largest events, IDOs and launches on the Solana blockchain - Step, Mango,
              Orca, and many more.
            </p>
            <hr class="w-full my-6 border-gray-300" />
          </div>
        </div>
        <div class="flex">
          <div class="mr-4">
            <div
              class="flex items-center justify-center w-10 h-10 mb-3 rounded-full  bg-indigo-50"
            >
              <svg
                class="w-8 h-8 text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                ></polygon>
              </svg>
            </div>
          </div>
          <div>
            <h6 class="mb-2 font-semibold leading-5">Highly available</h6>
            <p class="text-sm text-gray-500 md:text-base">
              With no single point of failure, we offer complete redundancy. Our custom monitoring 
              software stack ensures that we can always keep on top of what's happening on your servers.
              Our geographically distributed team ensures that there is always someone from our side available
              to support you and your app.
            </p>
          </div>
        </div>
      </div>
      <div class="">
        <img class="" src="./../assets/RPCPool.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: "RPCPool: Triton",
      meta: [
        {
          name: "description",
          content: "The original Solana RPC Pool service",
        },
      ],
    });
  },
  name: "RPCPool",
};
</script>
